import React from 'react';
import Lottie from  "lottie-react";
import SpaceBoy from "../LottieFiles/SpaceBoy.json";
import Tilt from 'react-parallax-tilt';
import Avatar from '../images/Avatar.png';

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>I'M <b>MARCO</b></h1>
          <div className="TypeEffect">
            <div class="Typewriter">
              <span class="typewriter_wrapper">Software Developer</span>
            </div>
          </div>
        </div>

        <Lottie
          className="illustration"
          animationData={SpaceBoy}
          loop={true}
        />

      </div>

      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>Brief <b>introduction</b></h1>
          <p>
          Hello, I'm <b>Marco</b>, and I'm thrilled to welcome you to my portfolio.
          I'm a dedicated <b>software developer</b> with a passion for crafting innovative solutions that leave a lasting impact.
          With a robust background in <b>Node.js</b>, <b>JavaScript</b>, <b>C#</b>, <b>C++</b>, <b>Express</b>, <b>MongoDB</b>, <b>Git</b>, and <b>GitHub</b>,
          and a commitment to continuous learning,
          I'm excited to take you on a journey through my world of coding.
          </p>
        </div>

        <Tilt>
          <img className='Avatar' src={Avatar} alt="" />
        </Tilt>
      </div>

      <div className='AboutPageBetter'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>My <b>Skills</b></h1>
          <p>
          My toolbox includes:
          <br/><br/>
          <ul>
            <li><b>Node.js</b>: I've harnessed the power of Node.js to create scalable and efficient web applications, solving real-world problems.</li>
            <br/>
            <li><b>JavaScript</b>: From front-end magic to back-end wizardry, JavaScript is my go-to language for crafting dynamic and engaging web experiences.</li>
            <br/>
            <li><b>C# and C++</b>: These languages have been my allies in building robust desktop applications, showcasing my expertise in object-oriented programming.</li>
            <br/>
            <li><b>Databases (MongoDB and MySQL)</b>: I'm proficient in data storage and retrieval, utilizing both MongoDB and MySQL to manage data effectively.</li>
            <br/>
            <li><b>Git and GitHub</b>: Version control and collaboration are at the heart of every project, and I'm well-versed in using Git and GitHub for efficient code management.</li>
            <br/>
            <li><b>NPM</b>: I leverage NPM to manage packages and dependencies, ensuring smooth development workflows.</li>
          </ul>
          </p>
        </div>
      </div>

      <div className='AboutPageBetter'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>My Learning <b>Journey</b></h1>
          <p>
          I'm currently immersed in the world of <b>Python</b>—a versatile language with diverse applications,
          from data analysis to automation.
          Learning is my perpetual journey, and <b>Python</b> is my latest adventure.
          </p>
        </div>
      </div>

    </div>
  )
}

export default Home