import React from 'react';
import ProjectBox from './ProjectBox';
import FourEveryoneImage from '../images/FourEveryoneImage.png'
import PhoenixInterfaceImage from '../images/PhoenixInterfaceImage.png';
import PhoenixDevelopmentImage from '../images/PhoenixDevelopmentImage.png';
import EXILEDPluginsImage from '../images/EXILEDPluginsImage.jpg'
import GmodAddonsImage from '../images/GmodAddonsImage.png'
import IoBrokerImage from '../images/IoBrokerImage.png'

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>My <b>Projects</b></h1>
      <div className='project'>
        <ProjectBox projectPhoto={FourEveryoneImage} projectName="4Everyone" />
        <ProjectBox projectPhoto={PhoenixInterfaceImage} projectName="Phoenix Interface" />
        <ProjectBox projectPhoto={PhoenixDevelopmentImage} projectName="Phoenix Development" />
        <ProjectBox projectPhoto={EXILEDPluginsImage} projectName="EXILED Plugins" />
        <ProjectBox projectPhoto={GmodAddonsImage} projectName="GmodAddons" />
        <ProjectBox projectPhoto={IoBrokerImage} projectName="IoBroker Adapters" />
      </div>

    </div>
  )
}

export default Projects