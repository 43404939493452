import React from 'react';
import {FaGithub} from "react-icons/fa";
import {CgFileDocument} from "react-icons/cg";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    '4EveryoneDesc' : "Beyond the realm of code, I value community. 4Everyone is an online haven I've cultivated, dedicated to inclusivity and connection, where everyone's voice is heard and valued.",
    '4EveryoneGithub' : "https://github.com/4Everyone-XYZ",
    '4EveryoneWebsite' : "https://4everyone.xyz",

    'Phoenix InterfaceDesc' : "Step into the realm of FiveM server administration effortlessly. Phoenix Interface is a user-friendly tool designed to simplify the intricacies of server management, catering to administrators' needs. This Project is discontinued",
    'Phoenix InterfaceGithub' : "",
    'Phoenix InterfaceWebsite': "https://fivem.phoenix-development.shop",

    'Phoenix DevelopmentDesc' : "This is my creative canvas for crafting scripts that inject life and excitement into FiveM servers. It's where I explore new horizons and push the boundaries of gaming experiences.",
    'Phoenix DevelopmentGithub' : "",
    'Phoenix DevelopmentWebsite': "https://fivem.phoenix-development.shop",

    'EXILED PluginsDesc': "I've extended my coding prowess to SCP Secret Laboratory, developing plugins that enhance gameplay and showcase my versatility as a developer.",
    'EXILED PluginsGithub': "https://github.com/Marco15453?tab=repositories&q=&type=&language=c%23&sort=",
    'EXILED PluginsWebsite': "https://github.com/Exiled-Team/EXILED",

    'GmodAddonsDesc': "As a testament to my commitment to gaming communities, I've created a marketplace for Garry's Mod plugins and addons, catering to the vibrant Garry's Mod community.",
    'GmodAddonsGithub': "",
    'GmodAddonsWebsite': "https://gmodaddons.com",

    'IoBroker AdaptersDesc': "My foray into the world of home automation and IoT. I've developed adapters for IoBroker, adding to my repertoire of versatile skills.",
    'IoBroker AdaptersGithub': "https://github.com/Marco15453?tab=repositories&q=ioBroker&type=&language=&sort=",
    'IoBroker AdaptersWebsite': "https://www.iobroker.net"
  }

  let show ='';
  if(desc[projectName + 'Github']===""){
    show="none";
  }

  return (
    <div className='projectBox'>
        <img className='projectPhoto' src={projectPhoto} alt="Project display" />
        <div>
            <br />
            <h3>{projectName}</h3>
            <br />
            {desc[projectName + 'Desc']}
            <br />

            <a style={{display:show}} href={desc[projectName + 'Github']} target='_blank'>
              <button className='projectbtn'><FaGithub/> Github</button>
            </a>

            <a href={desc[projectName + 'Website']} target='_blank'>
              <button className='projectbtn'><CgFileDocument/> Website</button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox